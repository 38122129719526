* {
    margin: 0;
    padding: 0;
    font-family: 'Outfit';
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    background-color: #f3f3f3 !important;
    font-family: Outfit !important;
}

.css-1v5u2yv {
    padding: .5rem 0rem 0rem 0rem !important;
    padding-top: 0 !important;
}

.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    background-color: #210720 !important;
    /* height: 76px; */
}

.ck-editor {
    color: black;
}

.col_custom {
    background-color: #f3f3f3;
}

.main_box_dashboard {
    display: none !important;
}

.table-responsive {
    overflow-x: clip !important;
}

.table_middle_text_box {
    transform: translate(0px, 15px);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.seo-note-box {
    margin-top: 1rem;
}

.seo-note-box .seo-note-title {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 500;
}

.seo-note-box .seo-note-title span {
    font-weight: 600;
}

.h_pop_up_img_box {
    height: 300px;
    width: 100%;
    margin-top: 2rem;
}

.h_pop_up_img_box img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.home_pop_up_form_g {
    border: 1px solid #d9d9d973;
    padding: 1rem;
}

.h_pop_up_main_title {
    font-size: 17px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.blog_desc .ck.ck-editor {
    position: relative;
    width: 100% !important;
}

.NoteBox {
    margin-top: 1.5rem;
    text-align: center;
}

.NoteBox p {
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.NoteBox p span {
    color: red;
}

.table-product-name {
    font-size: 13px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    width: 30vw;
    overflow: hidden;
    text-align: start;
}

.table-input {
    width: 10vw !important;
}

.plus-btn {
    background-color: green !important;
    border-radius: 100% !important;
    color: #fff !important;
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px !important;
}
.delete-btn {
    background-color: red !important;
    border-radius: 100% !important;
    color: #fff !important;
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px !important;
}

.plus-btn svg{
    font-size: 18px;
}
.delete-btn svg{
    font-size: 18px;
}

.planBtn {
    background-color: #0B951A;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 18px;
    cursor: pointer;
    border-radius: 30px;
    font-size: 14px;
    position: relative;
    overflow: hidden;
}

.planBtn:hover {
    background-color: #218838;
}

.planBtn::before {
    content: "";
    width: 15%;
    height: 300px;
    background-color: #fff;
    transform: rotate(30deg);
    position: absolute;
    top: -150px;
    left: -150px;
    opacity: .3;
    filter: blur(10px);
    animation: btnAnimation 3s linear infinite;
}

@keyframes btnAnimation {
    0% {
        left: -40px;
        opacity: .3;
    }

    24% {
        left: 150%;
        opacity: .3;
    }

    25% {
        left: 150%;
        opacity: 0;
    }

    30%, 100% {
        left: -150px;
        opacity: 0;
    }
}