.home-main {
    background-color: #EEE;
    height: 100%;
    padding-top: 1rem;
}

.home_c_card {
    border: none !important;
    height: 100% !important;
}

.home_card_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem 1rem .6rem 1.5rem !important;
    background: #FFF !important;
    border-bottom: 1px solid rgba(217, 217, 217, 0.42) !important;
}

.assignBtn {
    outline: none !important;
    border: none !important;
    padding: 0 !important;
}

.table-address {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 150px;
}

.home-icon-g {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #FD9811;
    font-family: 'Poppins', sans-serif;
}

.home-icon-info {
    margin-top: 1rem;
    margin-left: 1rem;
}

.icon-info-para {
    color: #454545;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-transform: capitalize;
}

.icon-info-t {
    color: #FD9811;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.home_card_body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.home_b_info {
    margin-left: 5px;
}

.b_info_p1 {
    color: #636363;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}

.b_info_p2 {
    color: #372036;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
    font-family: sans-serif;
    margin-top: 0.1rem;
}


/* second card */

.home-icon-g-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #DD2467;
    font-family: 'Poppins', sans-serif;
}

.icon-info-t-2 {
    color: #DD2467;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}


/* third card */

.home-icon-g-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #47A34B;
    font-family: 'Poppins', sans-serif;
}

.icon-info-t-3 {
    color: #47A34B;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}


/* fourth card */

.home-icon-g-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #0EB6CA;
    font-family: 'Poppins', sans-serif;
}

.icon-info-t-4 {
    color: #0EB6CA;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}


/* pravin css */

.home_table_card {
    border: none !important;
    border-radius: 0px !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}


/* 
.home_table_thead,
tr,
th {
    border: none !important;
} */

.home_table_main,
tr,
thead,
tbody,
td,
th {
    border: none !important;
}

.today_order_table {
    border: 1px solid #D6D6D6;
    background: #FFF;
    margin-top: 10px;
    padding: 13px 0px 10px 30px;
}

.cart_heading {
    display: flex;
}

.today_heading {
    color: #210720;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
    margin-top: 5px;
}

.show_entry_heading {
    color: #868686;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
    margin-left: 15px;
    text-transform: capitalize;
}

.show_entreis {
    border-radius: 3px;
    border: 1px solid #D6D6D6;
    background: #F8F8F8;
    height: 30px;
    /* width: 30px; */
    padding: 2px 9px 0px 8px;
    margin-top: 3px;
    margin-left: 20px;
}

.search_icon1 {
    position: absolute;
    right: 10px;
    /* Adjust this value to control the icon's right alignment */
    top: 50%;
    transform: translateY(-50%);
    color: #555;
    /* Icon color */
    cursor: pointer;
}

.view_list_heading {
    color: #dc3545;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search_input1 {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #FDFDFD;
}

.search_icon2 {
    width: 91px !important;
}

.today_order_Search {
    position: relative;
    display: inline-block;
}

.search_icon3 {
    position: absolute;
    right: 10px;
    /* Adjust this value to control the icon's right alignment */
    top: 50%;
    transform: translateY(-50%);
    color: #555;
    /* Icon color */
    cursor: pointer;
}

.view_list_kanban {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #dc3545;
    background: #ffd8db;
    height: 38px !important;
    border-right: none;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list {
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #dc3545;
    border-right: none;
    background: #ffd8db;
    height: 38px !important;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list1 {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #d6d6d6;
    background: #ffffff;
    height: 38px !important;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list2 {
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #d6d6d6;
    background: #ffffff;
    height: 38px !important;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list_heading1 {
    color: #9A9A9A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.kanban_input {
    width: 50px !important;
}

.view_input {
    display: flex;
    margin-left: 10px;
    margin-right: 20px;
}

.table-striped {
    border: 1px solid #dddd;
}

.table-striped {
    text-align: center;
    color: #3B3B3B !important;
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-transform: capitalize; */
}

.main_table_head th {
    background-color: #625c5c !important;
    color: #FFF !important;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}

.padding_bt {
    padding-bottom: 1.2rem !important;
}

.view_address {
    border-radius: 2px;
    border: 0.9px solid #0094FF;
    color: #0094FF;
    padding: 0px 8px;
    cursor: pointer;
    /* margin-left: 55px; */
}

.confirm_status {
    border-radius: 4px;
    border: 1px solid #008000;
    background: rgba(219, 238, 219, 0.70);
    padding: 0px 8px;
    color: #008000 !important;
}

.cancel_status {
    border-radius: 4px;
    border: 1px solid #ee3a4b;
    background: #ffe7e9;
    padding: 0px 8px;
    color: #ee3a4b !important;
    cursor: text !important;
}

.pending_status {
    border-radius: 4px;
    border: 1px solid #a9a9a9;
    background: #ebebeb;
    padding: 0px 8px;
    color: #6b6b6b !important;
    cursor: text !important;
}

.dispatch_status {
    border-radius: 4px;
    border: 1px solid #04afad;
    background: #dff6fa;
    padding: 0px 8px;
    color: #04afad !important;
    cursor: text !important;
}

.custom_variant {
    font-size: 14px !important;
}

.complete_status {
    border-radius: 4px;
    border: 1px solid #0a9c02;
    background: rgba(228, 250, 228, 0.7);
    padding: 0px 8px;
    color: #0a9c02 !important;
    cursor: text !important;
}

.confirm_img {
    height: 25px !important;
    object-fit: contain;
}

.sortable_order {
    display: flex;
    align-items: center;
}

.icons_sorting {
    margin: 0px !important;
    padding: 0px !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #ee3a4b !important;
    color: white !important;
}

.pagination_table {
    display: flex;
    justify-content: flex-end;
}


/* new home box */

.main_div_home {
    background-color: #F0F6FFE5;
    height: 100%;
}

.seller_box {
    box-shadow: 0px 4px 5.7px 0px #0000001A;
    background-color: #FFFFFF;
    height: auto;
    margin-block: 20px;
    border-radius: 10px;
    padding: 12px 25px;
}

.seller_title {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.94px;
    text-align: left;
    margin-bottom: 0px;
}

.main_box {
    display: flex;
    gap: 15px;
    /* flex-wrap: wrap; */
    /* Allow items to wrap if they don't fit in a single line */
    justify-content: space-between;
    /* Space items evenly */
}

.sub_main_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 1 calc(25% - 30px);
    box-sizing: border-box;
}

.first_sale_box {
    background-image: url("../../component/assets/images/new_order.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second_sale_box {
    background-image: url("../../component/assets/images/today_order.png");
}

.third_sale_box {
    background-image: url("../../component/assets/images/cancel_order.png");
}

.fourth_sale_box {
    background-image: url("../../component/assets/images/Delivered_Order.png");
}

.order_type {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.9px;
    text-align: center;
    color: #626262;
    text-wrap: nowrap;
    text-transform: capitalize;
}

.sale_count {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 42.84px;
    text-align: center;
    color: #FFFFFF;
}

.product_listing_box {
    background-color: rgba(113, 209, 97, 0.1);
    border-radius: 10px;
    /* opacity: 10%; */
    height: 65px;
    width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 13px;
}

.product_listing_box_1 {
    background-color: rgba(97, 196, 209, 0.1);
}

.product_listing_box_2 {
    background-color: rgba(209, 137, 97, 0.1);
}

.product_listing_box_3 {
    background-color: rgba(209, 137, 97, 0.1);
}

.product_listing_box_4 {
    background-color: rgba(254, 186, 10, 0.1);
}

.mg_type {
    margin-top: 10px;
}

.sale_count_cl {
    color: #48D331 !important;
    margin-bottom: 0px;
}

.activation_count {
    color: #18CBE4 !important;
    margin-bottom: 0px;
}

.inactive_count {
    color: #FB1D1D !important;
    margin-bottom: 0px;
}

.out_stk_count {
    color: #CD6F17 !important;
    margin-bottom: 0px;
}

.lw_stk_count {
    color: #FEBA0A !important;
    margin-bottom: 0px;
}

.table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table_search_filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
}

.svg_sub_div {
    border: 0.5px solid #D9D9D9;
    border-radius: 8px;
    padding: 6px 8px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.transaction_table {
    font-family: Poppins;
    border-collapse: collapse;
    width: 100%;
    margin-block: 15px;
}


/* Custom scrollbar styles */


/* width */

::-webkit-scrollbar {
    height: 3px;
    /* Use height for horizontal scrollbar */
    margin-block: 20px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #888;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.wallet_transaction_table {
    width: 100%;
    overflow-x: auto;
    /* Enable horizontal scroll */
    overflow-y: hidden;
    /* Hide vertical scroll if not needed */
    -webkit-overflow-scrolling: touch;
    /* For smooth scrolling on touch devices */
}

tr.table_heading_bg th {
    background-color: #F5F6FB;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    text-transform: capitalize;
    text-align: center;
    font-family: Outfit;
}

tr.table_heading_body td {
    font-family: Outfit;
    padding: 0px 20px;
    font-size: 13px;
    font-weight: 400;
    color: #626262;
    text-transform: capitalize;
    text-align: center;
    text-wrap: nowrap;
}

.input_div_wallet {
    width: 75%;
}


/* Container for the search component */

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}


/* Search icon container */

.svg_sub_div {
    cursor: pointer;
}


/* Search bar styles */

.search-bar {
    position: absolute;
    top: 50%;
    left: -217px;
    transform: translateY(-50%);
    overflow: hidden;
    transition: width 0.5s ease;
}

.search_bar_all_order {
    font-size: 16px;
    font-family: Outfit;
    font-weight: 400;
}

.search_bar_active {
    z-index: 1;
    border: none;
    /* border-radius: 0px 5px 5px 0px; */
    user-select: none;
}


/* When search bar is open */

.search-bar input {
    width: 250px;
    padding: 0px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: width 0.5s ease;
    padding-block: 2px;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
}

.dropdown-menu li {
    padding: 10px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

@media (max-width:100px) {
    .main_box {
        flex-wrap: wrap;
    }
}

@media (min-width:1300px) and (max-width:1400px) {
    .home-icon-g,
    .home-icon-g-2,
    .home-icon-g-3,
    .home-icon-g-4 {
        width: 50px;
        height: 50px;
    }
    .home-icon-g svg,
    .home-icon-g-2 svg,
    .home-icon-g-3 svg,
    .home-icon-g-4 svg {
        width: 30px;
        height: 30px;
    }
    .icon-info-para {
        font-size: 14px;
    }
    .icon-info-t,
    .icon-info-t-2,
    .icon-info-t-3,
    .icon-info-t-4 {
        font-size: 20px;
    }
    .padding_bt {
        padding-bottom: 0.6rem !important;
    }
    .home_card_body {
        flex-direction: column;
        padding: 0.5rem;
    }
    .b_info_p1 {
        font-size: 13px;
    }
    .b_info_p2 {
        font-size: 14px;
        font-family: auto;
    }
    .b_info_p1_tracked_f_database {
        margin-top: 0rem !important;
    }
}

@media (min-width:1500px) {
    .product_listing_box {
        height: 90px;
        width: 140px;
        margin-block: 0px;
    }
    .first_sale_box {
        margin-block-end: 10px;
    }
    .sub_main_box {
        margin-block: 10px;
    }
    .mg_type {
        margin-top: 20px;
    }
}